import Element from './Element';
import { remove as arrayRemove } from './util/Array';

export default class ControlledElement extends Element {
  controllers = [];

  // Handlers
  #onControllerClickHandler = this.#onControllerClick.bind(this);

  // States
  isOn = false;

  constructor(element) {
    super(element);
    this.#setupControllers();
  }

  #setupControllers() {
    [...document.querySelectorAll(`[aria-controls="${ this.name }"]`)]
      .forEach(element => this.addController(element));
  }

  addController(element) {
    element.addEventListener('click', this.#onControllerClickHandler, false);
    this.controllers.push(element);
  }

  removeController(element) {
    element.removeEventListener('click', this.#onControllerClickHandler, false);
    arrayRemove(this.controllers, element);
  }

  #onControllerClick(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.toggle();
  }

  toggle() {
    !this.isOn ? this.on() : this.off();
  }

  on() {
    if(this.isOn) {
      return;
    }

    this.isOn = true;
    this.#updateAttributes();
    this.onOn();
  }
  onOn() {}

  off() {
    if(!this.isOn) {
      return;
    }

    this.isOn = false;
    this.#updateAttributes();
    this.onOff();
  }
  onOff() {}

  #updateAttributes() {
    this.controllers.forEach(controller => controller.setAttribute('aria-expanded', this.isOn));
    this.onUpdateAttributes();
  }
  onUpdateAttributes() {}

  destroy() {
    this.controllers.forEach(element => this.removeController(element));
  }
}
