import { create as createPromise } from './util/Promise';

const _readyPromise = createPromise();
let _callbacks = [];

function initLoad() {
  return document && window ? document.addEventListener('DOMContentLoaded', loaded, false) : setTimeout(initLoad, 1);
  // TODO: Consider usinmg window load instead
  // return document && window ? window.addEventListener('load', loaded, false) : setTimeout(initLoad, 1);
}

function loaded() {
  document.removeEventListener('DOMContentLoaded', loaded, false)
  // TODO: Consider usinmg window load instead
  // window.removeEventListener('load', loaded, false);

  // Run queue
  _callbacks.forEach(cb => cb());
  _callbacks = null;
  _readyPromise.resolve();
}

function ready(callback) {
  if (!callback) {
    return _readyPromise;
  }

  if (_callbacks) {
    _callbacks.push(callback)
  } else {
    callback();
  }
}

const ANTI_core = {
  ready
};

initLoad();

export default ANTI_core;

// Class((function Main() {
//     !async function() {
//         if (TimelineOSC.timeSensitive = !0,
//         await Device.system.detectXR(),
//         Tests.usingVR() || (Mobile.fullscreen(),
//         Mobile.setOrientation("landscape")),
//         Mobile.resetOnRelease = !0,
//         VRInput.setControllerObject(EmptyController),
//         GameCenterMedia.connect("https://cloud.liveswitch.io/", "5015557a-b709-4ef3-a1d4-d59f85757e23", "c7342a894eb44d35bf28ae26d832715fa5019864609a4651af0fe77c8e8beca0"),
//         PlayerModel.set("usingVR", Tests.usingVR()),
//         GameCenterMedia.audioStream = !0,
//         Multiplayer.connect(Config.SOCKET_NET_SERVER, !1),
//         Utils.query("performance"))
//             return Performance.displayResults();
//         !function init() {
//             if (GLUI.init(),
//             GlobalAudio3D.pool = 16,
//             GlobalAudio3D.setup(GlobalAudio3D.RESONANCE_AUDIO),
//             window.location.search.includes("p="))
//                 return document.getElementById("initialLoader").remove(),
//                 AssetLoader.loadAssets(Assets.list().filter(["uil", "shaders"])).then(Playground.instance);
//             Container.instance()
//         }()
//     }()
// }
// ));
