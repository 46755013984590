import Overlay from '@/ANTICore/Overlay';
import VideoController from './VideoController';

export default class OverlayVideo extends Overlay {

  static CLICKABLES = ['IFRAME', 'BUTTON'];

  #iframe;
  #videoController;

  #bodyClickListener = this.#onBodyClick.bind(this);

  constructor(element) {
    super(element);

    this.#iframe = element.querySelector('iframe');
    this.#videoController = new VideoController(this.#iframe);
  }

  #onBodyClick(evt) {
    if (OverlayVideo.CLICKABLES.includes(evt.target.tagName)) {
      return;
    }
    this.close();
  }

  playVideo() {
    this.#videoController.play();
  }

  pauseVideo() {
    this.#videoController.pause();
  }

  close() {
    this.off();
  }

  onOn() {
    super.onOn();
    document.addEventListener('click', this.#bodyClickListener, false);
    this.playVideo();
  }

  onOff() {
    super.onOff();
    document.removeEventListener('click', this.#bodyClickListener, false);
    this.pauseVideo();
  }
  
}
