import Component from '@/ANTICore/Component';

export default class TextHighlight extends Component {

  element
  #container;
  #texthighlights;
  #images;

  constructor(element) {
    super(element);

    this.element = element;
    
    this.#getElements();
    this.#addEventListeners();
  }

  #getElements() {
    this.#container = this.element;
    this.#texthighlights = [...this.#container.querySelectorAll('.TextHighlight__text')];
    this.#images = [...this.#container.querySelectorAll('img')];
  }

  #addEventListeners() {
    if(this.#texthighlights.length > 0 && this.#images.length > 0) {
      this.#texthighlights.forEach((elm, index) => {
        elm.addEventListener('mouseenter', (event) => this.#onMouseEnter(event, elm, index), false);
        elm.addEventListener('mouseleave', (event) => this.#onMouseLeave(event, elm, index), false);
      });
    }
  }

  #onMouseEnter(event, elm, index) {
    // console.log('enter: ', index);
    elm.classList.add('active');
    this.#container.classList.add('active');
    const image = this.#images[index];
    if(image) {
      image.style.opacity = '1';
    }
  }

  #onMouseLeave(event, elm, index) {
    // console.log('leave: ', index);
    elm.classList.remove('active');
    this.#container.classList.remove('active');
    const image = this.#images[index];
    if(image) {
      image.style.opacity = '0';
    }
  }


}
