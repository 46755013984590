import { remove as arrayRemove } from './util/Array';

//
// TODO: Consider using object literal instead for singletons
// https://stackoverflow.com/questions/1479319/simplest-cleanest-way-to-implement-a-singleton-in-javascript/1479341#1479341
//
class Render {
  isPaused = false;
  #callbacks = [];
  #last = performance.now();
  #localTSL = 0;
  #elapsed = 0;
  #capLast = 0;
  #sampleRefreshRate = [];
  #firstSample = false;
  #refreshScale = 1;

  REFRESH_TABLE = [30, 60, 72, 90, 100, 120, 144, 240];
  REFRESH_RATE = 60;
  HZ_MULTIPLIER = 1;
  capFPS = null;
  delta = null;

  #render = (tsl) => {
    if (this.capFPS > 0) {
      let delta = tsl - this.#capLast;
      this.#capLast = tsl;

      if ((this.#elapsed += delta) < 1000 / this.capFPS) {
        return requestAnimationFrame(this.#render);
      }

      this.REFRESH_RATE = this.capFPS;
      this.HZ_MULTIPLIER = 60 / this.REFRESH_RATE * this.#refreshScale;
      this.#elapsed = 0;
    }

    this.delta = tsl - this.#last;
    this.#last = tsl;

    let delta = this.delta;
    delta = Math.min(200, delta);

    if(this.#sampleRefreshRate && !this.capFPS) {
      let fps = 1000 / this.delta;

      this.#sampleRefreshRate.push(fps);

      if (this.#sampleRefreshRate.length > 30) {
          // Pick median
          this.#sampleRefreshRate.sort((a, b) => a - b);
          let rate = this.#sampleRefreshRate[Math.round(this.#sampleRefreshRate.length / 2)];
          rate = this.REFRESH_TABLE.reduce(((prev, curr) => Math.abs(curr - rate) < Math.abs(prev - rate) ? curr : prev));

          this.REFRESH_RATE = this.#firstSample ? Math.max(this.REFRESH_RATE, rate) : rate;
          this.HZ_MULTIPLIER = 60 / this.REFRESH_RATE * this.#refreshScale;
          this.#sampleRefreshRate = null;
          this.#firstSample = true;
      }
    }

    this.TIME = tsl;
    this.DELTA = delta;
    this.#localTSL += delta;

    for (let i = this.#callbacks.length - 1; i >= 0; i--) {
      const callback = this.#callbacks[i];
      if (callback) {
        if (callback.fps) {
          if (tsl - callback.last < 1000 / callback.fps) {
            continue;
          }

          callback(++callback.frame);
          callback.last = tsl;
        } else {
          callback(tsl, delta);
        }
      } else {
        arrayRemove(this.#callbacks, callback);
      }
    }

    if(!this.isPaused) {
      requestAnimationFrame(this.#render);
    }
  }

  constructor() {
    requestAnimationFrame(this.#render);
    setInterval(() => this.#sampleRefreshRate = [], 3000);
  }

  start(callback, fps) {
    if(fps) {
      callback.fps = fps;
      callback.last = -1 / 0;
      callback.frame = -1;
    }

    if(!this.#callbacks.includes(callback)) {
      this.#callbacks.unshift(callback);
    }
  }

  stop(callback) {
    arrayRemove(this.#callbacks, callback);
  }

  pause() {
    this.isPaused = true;
  }

  resume() {
    if(this.isPaused) {
      this.isPaused = false;
      requestAnimationFrame(this.#render);
    }
  }
}

export default new Render();
