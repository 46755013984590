import Events from './Events.js';
import Render from './Render.js';

export default class Component extends Events {
  #loops = [];
  #invisible;

  onResize(callback) {
    this.events.subscribe(Events.EVENT_VIEWPORT_RESIZE, callback);
  }

  startRender(callback, fps, obj) {
    if("number" != typeof fps) {
      obj = fps;
      fps = undefined;
    }

    for (let i = 0; i < this.#loops.length; i++) {
      if (this.#loops[i].callback == callback) {
        return;
      }
    }

    let flagInvisible = _=> {
      if(!this.#invisible) {
        this.#invisible = true;

        if(this.onInvisible) {
          this.onInvisible();
        }
      }
    }

    let loop = (a, b, c, d) => {
      if(!this.startRender) {
        return false;
      }

      let parent = this;

      for (; parent; ) {
        if (false === parent.visible) {
          return flagInvisible();
        }

        if (parent.group && false === parent.group.visible) {
          return flagInvisible();
        }

        parent = parent.parent;
      }

      if(this.#invisible) {
        this.#invisible = false;

        if(this.onVisible) {
          this.onVisible();
        }
      }

      callback(a, b, c, d);

      return true;
    };

    this.#loops.push({
      callback: callback,
      loop: loop
    });

    if(obj) {
      // RenderManager.schedule(loop, obj);
    } else {
      Render.start(loop, fps);
    }
  }

  clearRenders() {
    for (let i = 0; i < this.#loops.length; i++) {
      Render.stop(this.#loops[i].loop);
    }

    this.#loops.length = 0;
  }

  stopRender(callback, obj) {
    for (let i = 0; i < this.#loops.length; i++) {
      if (this.#loops[i].callback == callback) {
        let loop = this.#loops[i].loop;

        if(obj) {
          // RenderManager.unschedule(loop, obj)
        }

        Render.stop(loop);
        this.#loops.splice(i, 1);
      }
    }
  }

  destroy() {
    if(this.onDestroy) {
      this.onDestroy();
    }

    if(this.clearRenders) {
      this.clearRenders();
    }

    if(this.events) {
      this.events = this.events.destroy();
    }
  }
}
