// Import favicons
import faviconAlternate from './img/favicon.png';
import favicon from './img/favicon.svg';

//
// https://babeljs.io/docs/en/babel-plugin-syntax-dynamic-import
//
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

//
// Bundle fonts
//
import '@/fonts/vulf-sans/Vulf_Sans-Regular.woff2';
import '@/fonts/vulf-sans/Vulf_Sans-Regular.woff';
import '@/fonts/vulf-sans/Vulf_Sans-Italic.woff2';
import '@/fonts/vulf-sans/Vulf_Sans-Italic.woff';
import '@/fonts/vulf-sans/Vulf_Sans-Medium.woff2';
import '@/fonts/vulf-sans/Vulf_Sans-Medium.woff';
import '@/fonts/vulf-sans/Vulf_Sans-Bold.woff2';
import '@/fonts/vulf-sans/Vulf_Sans-Bold.woff';
import '@/fonts/vulf-mono/Vulf_Mono-Regular_web.woff2';
import '@/fonts/vulf-mono/Vulf_Mono-Regular_web.woff';
import '@/fonts/vulf-mono/Vulf_Mono-Italic_web.woff2';
import '@/fonts/vulf-mono/Vulf_Mono-Italic_web.woff';

//
// Components
//
import OverlayVideo from '@/components/OverlayVideo';
import Slider from '@/components/Slider';
import InViewport from '@/components/InViewport';
import CopyToClipboard from '@/components/CopyToClipboard';
import TextHighlight from '@/components/TextHighlight';
import ScrollHandler from '@/components/ScrollHandler';
import EmbedVideo from '@/components/EmbedVideo';


//
// Application
//
const NordicOceanWatch = {
  // Options / globals
  body: document.body,
  scrollHandler: null,

  init() {
    this.setupScrollTos();
    this.setupAnimations();
    this.setupIsInView();

    document.querySelector('#NavTrigger').addEventListener('click', evt => {
      document.body.classList.toggle('nav-open');
    }, false);


    [...this.body.querySelectorAll('.OverlayVideo')].forEach(el => {
      new OverlayVideo(el);
    });

    // Init sliders
    [...this.body.querySelectorAll('.Slider')].forEach(el => new Slider(el));

    // Copy to clipboard
    [...this.body.querySelectorAll('[data-cc]')].forEach(el => new CopyToClipboard(el));

    // TextHighlights
    [...this.body.querySelectorAll('.TextHighlight')].forEach(el => new TextHighlight(el));

    // Video embeds
    [...this.body.querySelectorAll('.EmbedVideo')].forEach(el => new EmbedVideo(el));

    // Button Icon inherit hover
    [...document.querySelectorAll('.Button')].forEach(el => {
      const icon = el.querySelector('.Icon--animate');
      if(icon) {
        el.addEventListener("mouseenter", () => { icon.classList.add('active'); });
        el.addEventListener("mouseleave", () => { icon.classList.remove('active'); });
      }
    });

    // On browser resize
    // window.addEventListener('resize', () => this.onResize());
    // window.addEventListener('scroll', () => this.onScroll());

    this.scrollHandler = ScrollHandler();
    this.scrollHandler.listen('pass:768', evt => this.body.classList.toggle('has-scrolled-past-768', evt.detail.isPast), false);

  },


  setupScrollTos() {
    [...document.querySelectorAll('[data-scroll-to]')].forEach((el, i) => {
      if(!el.dataset.scrollTo) {
        return;
      }

      const target = document.querySelector(el.dataset.scrollTo);

      if (!target) {
        return;
      }

      el.addEventListener('click', (evt) => {
        evt.preventDefault();

        scrollTo(target);
      });
    });
  },

  setupAnimations() {
    const handleIntersection = (entries, observer) => {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          entry.target.classList.add('is-animated');
        }
      });
    }

    let observer = new IntersectionObserver(handleIntersection, {
      // rootMargin: '0px',
      threshold: 0.15
    });

    [...document.querySelectorAll('.should-animate')].forEach(el => observer.observe(el));
  },

  setupIsInView() {
    this.viewportObserver = new InViewport();
    [...this.body.querySelectorAll('[data-in-viewport]')].forEach(el => this.viewportObserver.observe(el));
  },

  initOnLoad() {
  }
};

document.addEventListener('DOMContentLoaded', () => NordicOceanWatch.init());
window.addEventListener('load', () => NordicOceanWatch.initOnLoad());

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
