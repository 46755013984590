// Inervse lerp function helper
const invLerp = (start, end, progress) => (progress - start) / (end - start);

// Clamp number helper
const clamp = (min, max, value) => Math.min(Math.max(value, min), max);

// Throttle helper
const throttle = (fn, wait) => {
  let inThrottle, lastFn, lastTime;
  return function() {
    const context = this,
      args = arguments;
    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(function() {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};

export default class InViewport {
  constructor() {
    this._itemsInView = new Map();
    this.registerEventHandlers();
  }
  registerEventHandlers() {
    // Handlers
    this._handleOnIntersection = this._onIntersection.bind(this);
    this._handleOnCenter = this._onCenterIntersection.bind(this);
    this._handleOnInset = this._onInsetIntersection.bind(this);
    this._handleOnScroll = this._onScroll.bind(this);
    this._handleOnResize = this._onResize.bind(this);
    this._observer = new IntersectionObserver(this._handleOnIntersection, {
      threshold: 0
    });
    this._centerObserver = new IntersectionObserver(this._handleOnCenter, {
      rootMargin: `${-100 * (1 - 0.5)}% 0px ${-100 * 0.5}%`
    });
    // this._insetObserver = new IntersectionObserver(this._onInsetIntersection, {
    //   // threshold: 0,
    //   rootMargin: `${-100 * (1 - 0.85)}% 0px ${-100 * 0.15}%`
    // });
    window.addEventListener('resize', throttle(this._handleOnResize, 250), false);
    window.addEventListener('scroll', this._handleOnScroll, false);
  }
  _onResize() {
    this._onScroll();
  }
  _onCenterIntersection(entries) {
    entries.forEach(entry =>
      entry.target.classList.toggle('in-center', entry.isIntersecting)
    )
  }
  _onInsetIntersection(entries) {
    entries.forEach(entry => {
      if(entry.isIntersecting) {
        entry.target.classList.add('in-inset');
        entry.target.classList.add('been-in-inset');
      } else {
        entry.target.classList.remove('in-inset');
      }
    });
  }
  _onIntersection(entries) {
    const {
      _itemsInView
    } = this;
    const currentY = window.pageYOffset;
    entries.forEach(entry => {
      const target = entry.target;
      const shouldUseProgress = target.hasAttribute('data-in-viewport-progress');
      if(entry.isIntersecting) {
        if (shouldUseProgress) {
          _itemsInView.set(target, {
            minY: entry.boundingClientRect.top + currentY - entry.rootBounds.height,
            maxY: entry.boundingClientRect.bottom + currentY
          });
        }
        this._centerObserver.observe(target);
        // this._insetObserver.observe(target);
        target.classList.add('in-viewport');
        target.classList.add('been-in-viewport');
      } else {
        if (shouldUseProgress) {
          _itemsInView.delete(target);
        }
        this._centerObserver.unobserve(target);
        // this._insetObserver.unobserve(target);
        target.classList.remove('in-viewport');
      }
    });
  }
  _onScroll(evt) {
    const {
      _itemsInView
    } = this;
    const currentY = window.pageYOffset;
    for (let [item, bounds] of _itemsInView) {
      item.style.setProperty('--viewport-progress-y', clamp(0, 1, invLerp(bounds.minY, bounds.maxY, currentY)));
    }
  }
  observe(target) {
    this._observer.observe(target);
  }
  unobserve(target) {
    this._observer.unobserve(target);
    // TODO: We might have to delete item from our Set as well
    // Depending if intersection is triggered when unobserve or not
    // this._itemsInView.delete(target)
  }
}
