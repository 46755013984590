export const defer = window.requestAnimationFrame;
export const onIdle = window.requestIdleCallback;

export function debounce(fn, ms = 0) {
  let timeoutId;

  return function(...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};
