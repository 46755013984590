import { random } from './Math';

export function shuffle(array) {
  let i = array.length - 1;
  let temp, r;
  while (i !== 0) {
    r = random(0, i, 0);
    i -= 1;
    temp = array[i];
    array[i] = array[r];
    array[r] = temp;
  }

  return array;
};

// TODO: Not sure what this does
// Array.storeRandom = function(arr) {
//   arr.randomStore = [];
// };


// Array.prototype.random = function(range) {
//   let value = Math.random(0, this.length - 1);
//   if (arguments.length && !this.randomStore) Array.storeRandom(this);
//   if (!this.randomStore) return this[value];
//   if (range > this.length - 1) range = this.length;
//   if (range > 1) {
//       while (!!~this.randomStore.indexOf(value)) if ((value += 1) > this.length - 1) value = 0;
//       this.randomStore.push(value);
//       if (this.randomStore.length >= range) this.randomStore.shift();
//   }
//   return this[value];
// };

export function remove(array, element) {
  if (!array.indexOf) {
    return;
  }

  const index = array.indexOf(element);

  if (!!~index) {
    return array.splice(index, 1);
  }
};

export function last(array) {
  return array[array.length - 1];
};
