export default class CopyToClipboard {

  constructor(root) {
    this.root = root;
    this.value = this.root.dataset.cc;

    // Text
    this.text = this.root.dataset.ccText,
    this.successText = this.root.dataset.ccSuccess,
    this.output = this.root.querySelector('[data-cc-output]'),

    // Listen for click
    this.clickListener = this.handleClick.bind(this);
    this.root.addEventListener('click', this.clickListener);
  }

  handleClick() {
    navigator.clipboard.writeText(this.value)
    .then(() => {
      // clipboard successfully set
      this.output.innerText = this.successText;
    })
    .catch(() => {
      // clipboard write failed...
      this.output.innerText = 'something went wrong...';
    })
    .finally(() => {
      setTimeout(() => {
        this.output.innerText = this.text;
      }, 2000);
    })
  }

}
