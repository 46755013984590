import BaseComponent from '@/components/BaseComponent';

export class ScrollHandler extends BaseComponent {
  constructor() {
    super(...arguments);
    this.scrollTop = 0;
    this.isPast50 = false;
    this.isScrollingDown = false;
    this.isAtTop = true;
    this._onScrollHandler = this.onScroll.bind(this);
    this.root = window;

    this.doc = document.documentElement;
    window.addEventListener('scroll', this._onScrollHandler, {
      passive: true
    });
  }

  onScroll() {
    const prevScrollTop = this.scrollTop;

    if((window.pageYOffset || this.doc.scrollTop) - (this.doc.clientTop || 0) !== this.scrollTop) {
      this.scrollTop = (window.pageYOffset || this.doc.scrollTop) - (this.doc.clientTop || 0);
    }

    this.scrollTop = Math.max(this.scrollTop, 0);

    //
    // Detect when at top
    //
    if (!this.isAtTop && this.scrollTop === 0) {
      this.isAtTop = true;
      this.emit('top', {state: true});
    } else if(this.isAtTop && this.scrollTop > 0) {
      this.isAtTop = false;
      this.emit('top', {state: false});
    }

    //
    // Detect scroll direction
    //
    if (!this.isScrollingDown && this.scrollTop > prevScrollTop) {
      this.isScrollingDown = true;
      this.emit('change:direction', {isScrollingDown: this.isScrollingDown});
    } else if (this.isScrollingDown && this.scrollTop < prevScrollTop) {
      this.isScrollingDown = false;
      this.emit('change:direction', {isScrollingDown: this.isScrollingDown});
    }

    //
    // Detect if we have scrolled 768px
    //
    if (this.isPast768 && this.scrollTop < 768) {
      this.isPast768 = false;
      this.emit('pass:768', {isPast: this.isPast768});
    } else if (!this.isPast768 && this.scrollTop > 768) {
      this.isPast768 = true;
      this.emit('pass:768', {isPast: this.isPast768});
    }

    //
    // Detect when past viewport
    //
    const viewportHeight = window.innerHeight;
    if (this.isPastViewport && this.scrollTop < viewportHeight) {
      this.isPastViewport = false;
      this.emit('pass:viewport', {isPast: this.isPastViewport});
    } else if (!this.isPastViewport && this.scrollTop > viewportHeight) {
      this.isPastViewport = true;
      this.emit('pass:viewport', {isPast: this.isPastViewport});
    }
  }
}

let _scrollHandler = null;
export default function getScrollHandler() {
  if (!_scrollHandler) {
    _scrollHandler = new ScrollHandler();
  }

  return _scrollHandler;
}