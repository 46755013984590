/**
 * Check to see if the URL is a Vimeo url.
 *
 * @param {string} url The url string.
 * @return {boolean}
 */
export function isVimeoUrl(url) {
  return (/^(https?:)?\/\/((player|www)\.)?vimeo\.com(?=$|\/)/).test(url);
}

export default class VideoController {

  constructor(root) {
    this.root = root;
    this._isVimeo = isVimeoUrl(root.src);
    this.registerEventHandlers();
  }

  registerEventHandlers() {
    // Handlers
    this._handleOnMessage = this._onMessage.bind(this);
    window.addEventListener('message', this._handleOnMessage);
  }
  
  _onMessage(evt) {
    // Check if event is from working iframe
    if (this.root.contentWindow !== evt.source) {
      return;
    }
  }

  play() {
    if(this._isVimeo) {
      this._cmd({
        method: "play",
        value: "true"
      });
    } else {
      this._cmd({
        event: "command",
        func: "playVideo"
      });
    }
  }

  pause() {
    if(this._isVimeo) {
      this._cmd({
        method: "pause",
        value: "true"
      });
    } else {
      this._cmd({
        event: "command",
        func: "pauseVideo"
      });
    }
  }

  _cmd(data) {
    this.root.contentWindow.postMessage(JSON.stringify(data), '*');
  }

}