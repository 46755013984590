export default class BaseComponent {
  /**
   * Wrapper method to add an event listener to the component's
   * root element. This is most useful when listening for custom events.
   */
  listen(evtType, handler, options) {
    this.root.addEventListener(evtType, handler, options);
  }

  /**
   * Wrapper method to remove an event listener to the component's root element. This
   * is most useful when unlistening for custom events.
   */
  unlisten(evtType, handler, options) {
    this.root.removeEventListener(evtType, handler, options);
  }

  /**
   * Fires a cross-browser-compatible custom event
   * from the component root of the given type, with the given data.
   */
  emit(evtType, evtData, shouldBubble = false) {
    let evt;
    if (typeof CustomEvent === 'function') {
      evt = new CustomEvent(evtType, {
        bubbles: shouldBubble,
        detail: evtData,
      });
    } else {
      evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(evtType, shouldBubble, false, evtData);
    }

    this.root.dispatchEvent(evt);
  }
}