
import Component from '@/ANTICore/Component';
import VideoController from './VideoController';
export default class EmbedVideo extends Component {

  element
  #root;
  #iframe;
  #playBtn;

  #onClickHandler = this.#onPlay.bind(this);

  constructor(element) {
    super(element);

    this.element = element;
    
    this.#getElements();
    this.#addEventListeners();
  }

  #getElements() {
    this.#root = this.element;
    this.#iframe = this.#root.querySelector('iframe');
    this.#playBtn = this.#root.querySelector('.EmbedVideo__playbtn');
    this.controller = new VideoController(this.#iframe);
  }

  #addEventListeners() {
    this.#playBtn.addEventListener('click', this.#onClickHandler);
  }

  #onPlay() {
    this.#root.classList.add('EmbedVideo--playing');
    this.controller.play();
  }

  #onPause() {
    this.#root.classList.remove('EmbedVideo--playing');
    this.controller.pause();
  }

}