import ANTI_core from './index.js';

import { remove as arrayRemove } from './util/Array.js';
import { debounce } from './util/Time.js';
import { create as createPromise } from './util/Promise.js';

class Emitter {
  events = [];

  #sweepScheduled = false;

  #check(evt) {
    if (void 0 === evt) {
      throw "Undefined event";
    }
  }

  addEvent(evt, callback, object) {
    this.#check(evt);

    this.events.push({
      evt: evt,
      object: object,
      callback: callback
    });
  }

  removeEvent(eventString, callback) {
    this.#check(eventString);

    for (let i = this.events.length - 1; i >= 0; i--) {
      if (this.events[i].evt === eventString && this.events[i].callback === callback) {
        this.#markForDeletion(i);
      }
    }
  }

  #sweepEvents() {
    for (let i = 0; i < this.events.length; i++) {
      if(this.events[i].markedForDeletion) {
        delete this.events[i].markedForDeletion;
        this.events.splice(i, 1);
        --i;
      }
    }
  }

  #markForDeletion(i) {
    this.events[i].markedForDeletion = true;

    if(!this.#sweepScheduled) {
      this.#sweepScheduled = true;
      requestAnimationFrame(() => {
          this.#sweepScheduled = false;
          this.#sweepEvents();
        }
      );
    }
  }

  fireEvent(eventString, obj) {
    this.#check(eventString);

    obj = obj || {};
    let called = false;

    for (let i = 0; i < this.events.length; i++) {
      let evt = this.events[i];
      if(evt.evt === eventString && !evt.markedForDeletion) {
        evt.callback(obj);
        called = true;
      }
    }

    return called;
  }

  destroyEvents(object) {
    for (var i = this.events.length - 1; i >= 0; i--) {
      if(this.events[i].object === object) {
        this.#markForDeletion(i);
      }
    }
  }

  saveLink(obj) {
    if(!this.links) {
      this.links = [];
    }

    if (!~this.links.indexOf(obj)) {
      this.links.push(obj);
    }
  }

  createLocalEmitter() {
    return new Emitter;
  }
}

export default class Events {
  //
  // Events
  //
  static EVENT_VIEWPORT_RESIZE = "viewport_resize";
  static EVENT_PAGE_UNLOAD = "page_unload";
  static EVENT_PAGE_VISIBILITY = "page_visibility";
  static emitter = new Emitter;

  events = {};
  #linkedEmitters = [];
  #localEmitter;

  constructor() {
    /**
     * Subscribe to an event
     * @param {Events=} obj Subscribe to local event
     * @param {string} evt Type of event to listen for
     * @param {Function} callback Function to call in response to given event
     *
     * @return {Function} callback
     */
    this.events.subscribe = (obj, evt, callback) => {
      // Handle as local event
      if(obj instanceof Events) {
        // Get local emitter
        let emitter = obj.events.emitter();
        emitter.addEvent(evt, callback.resolve ? callback.resolve : callback, this);
        emitter.saveLink(this);
        this.#linkedEmitters.push(emitter);

        return callback;
      }

      //
      // Handle as global event.
      //

      // Remap values if no object is set.
      callback = evt;
      evt = obj;
      obj = null;

      // Listen to global event
      Events.emitter.addEvent(evt, callback.resolve ? callback.resolve : callback, this);

      return callback;
    };

    this.events.wait = async (obj, evt) => {
      const promise = createPromise();
      const args = [obj, evt, e => {
        this.events.unsubscribe(...args);
        promise.resolve(e);
      }];

      if(!(obj instanceof Events)) {
        args.splice(1, 1);
      }

      this.events.subscribe(...args);

      return promise;
    }

    /**
     * Unsubscribe an event
     * @param {Events=} obj Object to unsubscribe local event from
     * @param {string} evt Event to unsubscribe from
     * @param {Function} callback Callback to remove.
     *
     * @return {Function} callback
     */
    this.events.unsubscribe = (obj, evt, callback) => {
      // Handle as local event
      if(obj instanceof Events) {
        return obj.events.emitter().removeEvent(evt, callback.resolve ? callback.resolve : callback);
      }

      //
      // Handle as global event.
      //

      // Remap values if no object is set.
      callback = evt;
      evt = obj;
      obj = null;

      // Remove global event
      return Events.emitter.removeEvent(evt, callback.resolve ? callback.resolve : callback);
    }

    this.events.emit = (evt, obj, isLocalOnly) => {
      obj = obj || {};
      // obj.target = this;

      if(this.#localEmitter) {
        this.#localEmitter.fireEvent(evt, obj);
      }

      if(!isLocalOnly) {
        Events.emitter.fireEvent(evt, obj);
      }
    }

    this.events.bubble = (obj, evt) => {
      this.events.subscribe(obj, evt, e => this.events.emit(evt, e));
    }

    this.events.destroy = () => {
      Events.emitter.destroyEvents(this);

      if(this.#linkedEmitters) {
        this.#linkedEmitters.forEach(emitter => emitter.destroyEvents(this));
      }

      if(this.#localEmitter && this.#localEmitter.links) {
        this.#localEmitter.links.forEach(obj => {
          obj.unlink(this.#localEmitter);
        });
      }

      return null;
    }

    /**
     * Get local emitter. This creates an local emitter if not already exisits
     */
    this.events.emitter = () => {
      if(!this.#localEmitter) {
        this.#localEmitter = Events.emitter.createLocalEmitter();
      }

      return this.#localEmitter;
    }

    this.events.unlink = (emitter) => {
      arrayRemove(this.#linkedEmitters, emitter);
    }
  }
}

//
// Register global events
//
// let iosResize = "ios" === Device.system.os;
// let delay = iosResize ? 500 : 16;
ANTI_core.ready(() => {
  function onResize() {
    Events.emitter.fireEvent(Events.EVENT_VIEWPORT_RESIZE, {
      width: (window.innerWidth || document.body.clientWidth || document.documentElement.offsetWidth),
      height: (window.innerHeight || document.body.clientHeight || document.documentElement.offsetHeight)
    });
  }

  window.addEventListener('resize', debounce(onResize, 100));
  requestAnimationFrame(onResize);

  window.addEventListener('beforeunload', () => Events.emitter.fireEvent(Events.EVENT_PAGE_UNLOAD));

  (function () {
    let _last;
    let _lastTime = performance.now();

    function onFocus() {
      if(_last != "focus") {
        Events.emitter.fireEvent(Events.EVENT_PAGE_VISIBILITY, {
          type: "focus",
          blurTime: -1
        });
      }

      _last = "focus";
    }

    function onBlur() {
      if(_last != "blur") {
        Events.emitter.fireEvent(Events.EVENT_PAGE_VISIBILITY, {
          type: "blur",
          blurTime: Date.now()
        });
      }

      _last = "blur"
    }

    //
    // Register visibility events
    //
    let hidden, eventName;
    [
      ["msHidden", "msvisibilitychange"],
      ["webkitHidden", "webkitvisibilitychange"],
      ["hidden", "visibilitychange"]
    ].forEach(d => {
      if(document[d[0]] !== undefined) {
        hidden = d[0];
        eventName = d[1];
      }
    });

    document.addEventListener(eventName, () => {
      const time = performance.now();

      if(time - _lastTime > 10) {
        document[hidden] === false ? onFocus() : onBlur()
      }

      _lastTime = time
    });
  })();
});
