import ControlledElement from './ControlledElement';
import FocusTrap from './FocusTrap';
import ScrollLock from './ScrollLock';
import { remove as arrayRemove } from './util/Array';

export default class Overlay extends ControlledElement {
  //
  // Events
  //
  static EVENT_OPEN = "overlay_open";
  static EVENT_CLOSE = "overlay_close";

  static openOverlays = [];

  #focusTrap;

  constructor(element) {
    super(element);
    this.#focusTrap = new FocusTrap(this.element);
  }

  open() {
    this.on();
  }

  close() {
    this.off();
  }

  onOn() {
    Overlay.openOverlays.push(this);
    this.events.emit(Overlay.EVENT_OPEN);
    ScrollLock.on();
    this.#focusTrap.trapFocus();
    document.body.classList.add('has-overlay-open');
    document.body.classList.add(`${this.name}-is-open`);
  }

  onUpdateAttributes() {
    if(this.isOn) {
      this.element.removeAttribute('hidden');
    } else {
      this.element.setAttribute('hidden', '');
    }
  }

  onOff() {
    arrayRemove(Overlay.openOverlays, this);
    this.events.emit(Overlay.EVENT_CLOSE);
    this.#focusTrap.releaseFocus();
    document.body.classList.remove(`${this.name}-is-open`);
    if(!Overlay.openOverlays.length) {
      ScrollLock.off();
      document.body.classList.remove('has-overlay-open');
    }
  }
}
